/*
   Ant Design Overrides
**/

$montserrat-regular: Montserrat-Regular, Arial, Helvetica, sans-serif;
$montserrat-semibold: Montserrat-SemiBold, Arial, Helvetica, sans-serif;

html {

  h1, h2, h3, h4, h5, h6 {
    font-family: $montserrat-regular;
  }

  /*
     Base Layout:
     Layout, Header, Content, Footer
  **/
  .ant-layout {
    font-family: $montserrat-regular;
  }

  /*
     Menu
  **/

  .ant-menu {
    font-family: $montserrat-semibold;
  }

  /*
     Tables
  **/
  .ant-table {
    font-family: $montserrat-regular;
  }

  .ant-popover {
    font-family: $montserrat-regular;
  }

  /*
     Modal
  **/
  .ant-modal {
    font-family: $montserrat-semibold;
    .ant-form-item {
      margin-bottom: 5px;
    }
    .ant-form-item:first-child {
      margin-top: 0;
    }
  }

  .ant-alert {
    font-family: $montserrat-semibold;
  }

  .ant-card {
    font-family: $montserrat-regular;
  }

///////////////////
  /*
    Row Data Display
  **/
  .ant-data-row {
    margin-bottom: 10px;
  }

  /*
     Table
  **/

  .ant-table-thead tr th {
    padding: 14px 10px;
  }

  /*
     Buttons
  **/

  button {
    font-family: $montserrat-regular;
  }

  .ant-btn-primary {
    background: $primary;
    border-color: $primary;
    &:hover {
      background: $highlight;
      border-color: $highlight;
    }
  }

  .ant-btn-secondary {
    background: $secondary;
  }

  /*
     Divider
  **/
  .ant-divider {
    &.invisible {
      background: none;
      margin: 14px 0;
    }
  }

  .ant-form-item, .ant-list, .ant-input, .ant-select, .ant-select-dropdown, .ant-select-tree, .ant-dropdown {
    font-family: $montserrat-regular;
  }

  /*
     Pagination
  **/
  @media (max-width: $screen-sm) {
    .ant-pagination-total-text {
      display: block;
    }

    .ant-pagination-item,
    .ant-pagination-prev {
      margin-right: 4px;
    }
  }

  /*
    Spinner
  **/
  .ant-spin-text {
    font-family: $montserrat-regular;
  }

  /*
    Drawer
  **/
  .ant-drawer-title {
    font-family: $montserrat-regular;
    font-size: 20px;
  }

  .ant-drawer-wrapper-body {
    overflow: auto !important;
  }

  .ant-checkbox-wrapper {
    font-family: $montserrat-regular;
  }

}

.homepage-right-drawer {
  & > .ant-drawer-content-wrapper {
    width: 50% !important;
    @media (max-width: $screen-md) {
      width: 100% !important;
    }
  }
}