/*
   Color Palette

   These colors should never be used in a component directly.
   Always assign them a functional variable name before use.

   Tool for color name lookup: http://chir.ag/projects/name-that-color
**/

$white: #ffffff;
$iron: #E0E1E2;
$black: #000000;

$pomegranate: #F52222;

$cornflower-blue: #0173B4;
$dodger-blue: #1890FF;
$dodger-blue-light: #40A9FF;
$half-baked: #85b4d6;
$denim: #1683d3;

$slate-gray: #6B778C;
$mishka: #DFE1E6;
$mercury: #E8E8E8;
$alto: #d9d9d9;
$athens-grey: #f0f2f5;

/*
   Forwood Style Guide Color Palette

   Comments indicate legacy color variables to replace with Forwood Palette colors
**/
$forwood-blue-1: #2ED2E9; // Legacy equivalent not yet used
$forwood-blue-2: #17A7FE; // Switch over all legacy dodger-blue-light
$forwood-blue-3: #0173B4; // Switch over all legacy cornflower-blue
$forwood-blue-4: #002A74; // Legacy equivalent not yet used

$forwood-gray-1: #DCDCDC; // switch over all legacy mishka
$forwood-gray-2: #B3B3B3; // Switch over all legacy iron
$forwood-gray-3: #656565; // Switch over all legacy slate-gray
$forwood-gray-4: #343434; // Legacy equivalent not yet used

/*
   Functional variables

   Components should refer to their functional name rather than the color name.
   These should always refer to a palette color from above
**/

$primary: $forwood-blue-3;
$highlight: $forwood-blue-2;
$secondary: $forwood-gray-1;
$error: $pomegranate;

// Auth
$auth-form-icon-color: rgba(0, 0, 0, .25);

// Layout
$layout-bg: $white;
$header-bg: $primary;

// General
$disabled-item-color: rgba($black, .3);

// Navigation
$nav-bar-bg: $primary;
$nav-bar-item-color: $white;
$nav-bar-item-color-hover: rgba($white, 0.8);
$nav-bar-item-color-selected: $highlight;
$nav-bar-sub-bg: $white;
$nav-bar-sub-item-color: $black;

// Sider menu
$side-menu-logo-bg: #002140;

// Forms
$form-label-color: $forwood-gray-3;

// Custom elements
$custom-field-display-border-color: $forwood-gray-1;

// User list
$user-list-table-bg: $white;
$user-list-table-row-bg: $white;
$user-list-col-header-bg: $forwood-blue-3;
$user-list-col-header-color: $white;
$user-list-col-header-row-color: rgba(94, 93, 82, 1);
$user-list-col-data-title-color: rgba(94, 93, 82, 0.55);
$user-list-col-border-color: $mercury;
$user-list-row-even-bg: rgba(0, 0, 0, 0.03);
$user-list-row-disabled-bg: rgba($forwood-gray-1, .5);
$user-list-row-disabled-color: $disabled-item-color;
$user-list-sort-col-bg: $forwood-blue-4;
$user-list-sort-control-color: rgba(255, 255, 255, 0.3);
$user-list-sort-border-color: $white;
$user-list-sort-control-span-color: rgba(255, 255, 255, 0.1);
$user-list-roles-icon-color: $primary;
$user-list-roles-icon-color-mobile: $white;
$user-list-name-link-color: $primary;
$user-list-filter-input-border-color: $alto;

// App Directory
$app-directory-disabled-bg: $mercury;
$app-directory-disabled-font-color: $forwood-gray-2;
