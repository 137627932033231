@import '~antd/dist/antd.css';
@import '../Sass/layout';
@import '../Sass/colors';
@import '../Sass/mixins';
@import '../Sass/utilities';
@import '../Sass/antd';
@import '../Sass/quill.scss';

@font-face {
  font-family: Montserrat-Regular;
  src: url('./fonts/Montserrat-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url('./fonts/Montserrat-SemiBold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Forwood-Regular;
  src: url('./fonts/FORWOOD_Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

#root {
  font-family: Montserrat-Regular, Arial, Helvetica, sans-serif;
}

/*
   Custom Form Label
**/
.custom-form-label {
  color: $form-label-color;
  font-family: Montserrat-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.33333;
  cursor: default;
}

.custom-field-display-box {
  cursor: default;
  font-family: inherit;
  border: 1px solid $custom-field-display-border-color;
  border-radius: 3px;
  min-width: 0;
  min-height: 27px;
  width: 100%;
  background: $white;
  display: inline-block;
  text-align: start;
  margin-bottom: 5px;
  padding: 3px 5px 3px 5px;
  word-wrap: break-word;
}

.flex-form-item {
  display: flex;
  align-items: center;

  label {
    margin-right: 1rem;
  }
}
