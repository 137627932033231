@import '../../Sass/layout';
@import '../../Sass/colors';

:local(.gridContainer) {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 4.16vw;
  row-gap: 4.16vw;

  cursor: grab;

  @media (max-width: $screen-md) {
    grid-template-columns: repeat(1, 1fr);
  }
}

:local(.dragged) {
  border: 2px dashed $primary !important;
}

:local(.showAppsBtn) {
  margin-top: 2.08vw;
}
